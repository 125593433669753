import React from "react";
import { Routes, Route } from "react-router-dom";
import Inquiries from "./inquiries";
import { Link } from "react-router-dom";
import Logo from "./TravelCarma-Logo.png";

const App = () => {
  return (
    <React.Fragment>
      <header className="pt-3 pb-3" style={{ boxShadow: "0 2px 8px #f0f1f2" }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <Link to="/" className="text-secondary">
                <img src={Logo} alt="TravelCarma" height="42px" />
              </Link>
            </div>
          </div>
        </div>
      </header>
      <div style={{ minHeight: "calc(100vh - 120px)" }}>
        <Routes>
          <Route key={1} path="/" element={<Inquiries />} />
        </Routes>
      </div>
      <footer className="pt-2 pb-2">
        <div className="container">
          <p className="text-center text-secondary small m-0">
            Copyright 2023 by TravelCarma All rights reserved
          </p>
        </div>
      </footer>
    </React.Fragment>
  );
};

export default App;
