import React, { useEffect, useState } from "react";

const Inquiries = () => {
  const [data, setData] = useState([]);

  const getInquiries = () => {
    // let url = "http://localhost/inquiry-management/default.php";
    let url = "https://iapi.travelcarma.com/default.php";

    let obj = {
      path: "get-inquiries",
    };
    var xhttp;
    xhttp = new XMLHttpRequest();
    xhttp.open("POST", url, true);
    xhttp.send(JSON.stringify(obj));
    xhttp.onreadystatechange = function () {
      if (this.readyState === 4 && this.status === 200) {
        let res = JSON.parse(this?.response);
        setData(res?.response || []);
      }
    };
  };

  useEffect(() => {
    getInquiries();
  }, []);
  return (
    <div className="container">
      <div className="mt-5">
        <table className="table table-bordered1">
          <thead>
            <tr>
              <th>Date</th>
              <th>Name</th>
              <th>Company</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Website</th>
              <th>Comments</th>
            </tr>
          </thead>
          <tbody>
            {data.map((x, key) => (
              <tr key={key}>
                <td>{x?.date?.split(" ")[0]}</td>
                <td>{x?.name}</td>
                <td>{x?.company}</td>
                <td>{x?.email}</td>
                <td>{x?.phone}</td>
                <td>{x?.website}</td>
                <td>{x?.comments}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="mt-4"></div>
      </div>
    </div>
  );
};

export default Inquiries;
